<template>
  <div class="content">
    <v-form ref="form" @submit.prevent="">
      <v-text-field dark outlined v-model="activity.name" label="Nombre"
            :rules="requiredField"></v-text-field>
      <v-textarea dark outlined v-model="activity.description" label="Descripción" rows="5"
            :rules="requiredField"></v-textarea>
      <v-text-field dark outlined v-model="activity.place" label="Lugar"
            :rules="requiredField"></v-text-field>
      <v-row class="date-view">
        <div class="ma-5">
          <p>Fecha de inicio:</p>
          <input class="mr-15" type="datetime-local" v-model="start_date" required/>
        </div>
        <div class="ma-5">
          <p>Fecha de inicio:</p>
          <input class="mr-15" type="datetime-local" v-model="end_date" required/>         
        </div>
      </v-row>     
        <v-select dark outlined v-model="activity.activity_type" :items="activity_types" item-text="name" label="Tipo" 
              return-value="value" :rules="requiredField"></v-select>           
      <v-row class="mt-5 mb-10">
        <v-spacer/>
        <input type="file" accept="image/png, image/jpeg" @change="onFileSelected" :rules="requiredField">
        <v-icon v-if="selectImage" style="color: white;" @click="deleteImage()">mdi-delete</v-icon>
        <v-spacer/>
      </v-row>
      <div v-if="selectImage">
        <hr class="ma-5"> 
        <v-row class="ma-10">
          <v-spacer/>
          <img style="width: 30%;" :src="selectImage"> 
          <v-spacer/>             
        </v-row>
        <hr class="ma-5">          
      </div> 
      <v-row class="center-content">
        <v-btn dark type="submit" @click="saveActivity()">Guardar</v-btn>
      </v-row>
    </v-form>        
  </div>
</template>

<script>

import ActivityRepository from "../../repositories/ActivityRepository";

export default {

  data() {
    return {
      activity: {
        name: "",
        description: "",
        activity_type: "",
        start_date: 0,
        end_date: 0,
        place: "",
        image: "",
        event_id: "",
      },
      activity_types: [
        { name: 'Comida', value: 'food' },
        { name: 'Deporte', value: 'sport' },
        { name: 'Música', value: 'music' },
        { name: 'Show', value: 'show' },
        { name: 'Otro', value: 'other' }
      ],
      start_date: "",
      end_date: "",
      requiredField: [(v) => !!v || "Este campo es obligatorio"],
      selectImage: "",
      edit_view: false,
    };
  },


   mounted() {
    if (this.$route.params.activity_id) {
      this.edit_view = true
      this.getActivity()
    }
  },

  methods: {

    async getActivity() {
      try {
        this.activity = await ActivityRepository.findActivityByEventId(this.$route.params.activity_id)
        this.selectImage = await ActivityRepository.getActivityImage(this.$route.params.activity_id)
        // Fechas del evento +2h local
        this.start_date = this.secondsToDate(this.activity.start_date + 7200)
        this.end_date = this.secondsToDate(this.activity.end_date + 7200)
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    async saveActivity() {
      //Comprobamos la validez de los datos
      if (!this.$refs.form.validate()) {
        return;
      }

      this.activity.image = this.selectImage
      this.activity.start_date = this.stringToSeconds(this.start_date)
      this.activity.end_date = this.stringToSeconds(this.end_date)

      try {
        if (this.edit_view) {
          await ActivityRepository.editActivity(this.activity).then(() => {
            this.goToActivityList()
          })
        } else {
          this.activity.event_id = this.$route.params.id
          await ActivityRepository.addNewActivity(this.activity).then(() => {
            this.goToActivityList()
          })
        }
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    async goToActivityList() {
      if (this.edit_view) {
        this.$router.push({ name: "CreateEditActivity", params: { id: this.activity.event_id } });
      } else {
        this.$router.push({ name: "CreateEditActivity", params: { id: this.$route.params.id } });
      }
    },

    deleteImage() {
      this.selectImage = null
      this.formData = null
    },

    onFileSelected(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.selectImage = reader.result.replace("jpeg", "png")
      }
    },

    secondsToDate(seconds) {
      const dateObject = new Date(seconds * 1000);
      return dateObject.toISOString().slice(0, -8);
    },

    stringToSeconds(value) {
      const date = new Date(value)
      return date.getTime()/1000
    },
  },
};
</script>

<style>

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.date-view {
  margin: 1px;
  margin-bottom: 40px;
  background-color: white;
  border-radius: 10px;
}

.content {
  margin-top: 25px;
}

</style>
