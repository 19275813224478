<template>
  <v-container>
    <v-col>
      <v-row>
        <v-btn class="mr-10" dark rounded @click="goToEvents()"> Eventos </v-btn>
        <v-btn class="mr-10" dark rounded @click="goToNewEvent()"> Nuevo evento </v-btn>
        <v-btn class="mr-10" dark rounded @click="goToListNotifications()"> Historico de notitificaciones </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
export default {
  methods: {
    goToEvents() {
      this.$router.push({ name: "EventList" });
    },
    goToNewEvent() {
      this.$router.push({ name: "CreateEvent" });
    },
    goToSendNotifications() {
      this.$router.push({ name: "SendNotification" });
    },
    goToListNotifications() {
      this.$router.push({ name: "NotificationList" });
    },
  },
};
</script>

<style scoped>
h1 {
  margin-top: 30px;
  margin-bottom: 60px;
}

.circle {
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  font-size: 30px;
  color: black;
  text-align: center;
  cursor: pointer;
}
</style>
