import HTTP from "@/common/http";

const resource = "activities";

export default {

  async findAllActivities() {
    const response = await HTTP.get(`${resource}`);
    return response.data;
  },

  async findActivitiesByEventId(id) {
    const response = await HTTP.get(`${resource}?event_id=${id}`);
    return response.data.body;
  },

  async addNewActivity(activity) {
    return await HTTP.post(`${resource}`, activity).data;
  },

  async editActivity(activity) {
    return await HTTP.put(`${resource}/${activity.id}`, activity).data;
  },

  async findActivityByEventId(id) {
    const response = await HTTP.get(`${resource}/${id}`);
    return response.data.body;
  },

  async getActivityImage(id) {
    const response = await HTTP.get(`${resource}/${id}/image`);
    return response.data.body;
  },

  async deleteActivity(id) {
    return await HTTP.delete(`${resource}/${id}`);
  },

};
