<template>
  <div class="container">
    <v-row class="ma-5">
      <v-spacer/>
      <h2 style="color: white;">NUEVO PACK</h2>
      <v-spacer/>
    </v-row>
    <v-form ref="form" @submit.prevent="">
      <v-text-field dark outlined v-model="pack.name" label="Nombre"
            :rules="requiredField"></v-text-field>
      <v-textarea dark outlined v-model="pack.description" label="Descripcion"
            rows="4" :rules="requiredField"></v-textarea>
      <v-text-field dark outlined v-model="pack.total_stock" label="Stock"
            :rules="requiredField"></v-text-field>
      <v-text-field dark outlined v-model="pack.price" label="Precio"
            :rules="requiredField"></v-text-field>
    </v-form>
    <v-row class="pa-5">
      <v-spacer/>
      <v-btn dark type="submit" @click="createPack()">Guardar</v-btn>
      <v-spacer/>
    </v-row>
  </div>
</template>

<script>

import PackRepository from "../../repositories/PackRepository";

export default {
  data() {
    return {
      pack: {
        name: "",
        description: "",
        price: null,
        current_stock: null,
        total_stock: null,
        event_id: this.$route.params.pack_id
      },
      requiredField: [(v) => !!v || "Este campo es obligatorio"],
      edit_view: false,
    };
  },

  async mounted() {
    if(this.$route.params.pack_id) {
      this.pack = await PackRepository.getPack(this.$route.params.event_id, this.$route.params.pack_id)
      this.edit_view = true
    }
  },

  methods: {

    async createPack(){

      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        if (this.edit_view) {
          await PackRepository.updatePack(this.$route.params.event_id, this.pack)
        } else {
          await PackRepository.createPack(this.$route.params.event_id, this.pack)
        }
        this.goToPackList()
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    goToPackList() {
      this.$router.push({ name: "PackList", params: { id: this.$route.params.event_id } });
    },

  },
};
</script>

<style>

</style>
