<template>
  <v-app style="background-color: transparent;">

    <v-app-bar app dark style="background-color: rgb(37, 37, 37);">  
      <img style="width: 40px;" src="./assets/logo_theok.svg" alt="">
      <v-spacer/>  
      <v-icon v-show="false" class="ml-8" color="green" @click="goLogin()">login</v-icon>
      <v-icon class="ml-8" color="red" @click="logout()">logout</v-icon>
    </v-app-bar>     

<!--    
    <v-navigation-drawer fixed permanent expand-on-hover dark style="background-color: rgb(37, 37, 37);">

      <v-list>
        <v-list-item>
          <img style="width: 30px;" src="./assets/logo_theok.svg" alt="">
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        
        <v-list-item @click="goToHome()">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToEventList()">
          <v-list-item-icon>
            <v-icon>celebration</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Eventos</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToNewEvent()">
          <v-list-item-icon>
            <v-icon>add_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo evento</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToNewItem()">
          <v-list-item-icon>
            <v-icon>menu_book</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Items</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToNotificationsHistory()">
          <v-list-item-icon>
            <v-icon>mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Historico notificaciones</v-list-item-title>
        </v-list-item>

        <v-list-item @click="goToUserList()">
          <v-list-item-icon>
            <v-icon>person</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuarios</v-list-item-title>
        </v-list-item>

        <v-list-item @click="modeUniqueEvent()">
          <v-list-item-icon>
            <v-icon>tune</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Modo evento: {{ mode_event_msg }}</v-list-item-title>
        </v-list-item>
      
        <v-list-item @click="goToUserList()">
          <v-list-item-icon>
            <v-icon>people</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Asistentes</v-list-item-title>
        </v-list-item>
        
      </v-list>

    </v-navigation-drawer>
-->
    <v-main>
      <v-dialog v-model="dialog" width="500">
        <PopUp :title="message" @close_dialog="closeDialog()"></PopUp>
      </v-dialog> 
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>

import store from "@/common/store";
import PopUp from "../src/entities/events/PopUp.vue"
import auth from "./common/auth";


export default {

  name: "App",
  data() {
    return {
      user: store.state.user,
      menu: true,
      navigation_drawer: false,
      success: false,
      mode_event_msg: "OFF",
      dialog: false,
      logged: true,
      message: "Va a cambiar el panel de control a MODO EVENTO"
    };
  },
  components: { PopUp },

  mounted() {
    //this.logged = this.getData('user_logged').user_logged
  },

  methods: {

    goToEventList() {
      this.$router.push({ name: "EventList" });
    },
    goToNotificationsHistory() {
      this.$router.push({ name: "NotificationList" });
    },
    goToHome() {
      this.$router.push({ name: "Home" });
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
    goToNewEvent() {
      this.$router.push({ name: "CreateEvent" });
    },
    goToNewItem() {
      this.$router.push({ name: "CreateItem" });
    },
    goToNewPack() {
      this.$router.push({ name: "CreatePack" });
    },
    goToUserList() {
      this.$router.replace({ name: "UserList" });
    },
    goBack() {
      this.$router.go(-1);
    },

    goLogin() {
      this.$router.push({ name: "Login" })
    },

    logout() {
      auth.logoutBatman()
      this.goLogin()
    },

    isLogged() {
      return auth.getLogged()
    },

  },
};

</script>


<style>

.container {
  margin-left: 13.5%;
}

</style>