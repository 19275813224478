  <template>
    <v-container>
      <v-col>
        <v-form ref="form" @submit.prevent="">
          <v-row class="pl-10 mb-5" style="background-color: whitesmoke; border-radius: 4px;">
            <h4 class="pr-5 pt-7">Programada</h4>
            <v-switch class="pt-3" color="black" v-model="auto" @click="switchAction()"></v-switch>
            <v-spacer/>
            <v-btn v-if="!editNotif" class="ma-5 mr-10" dark @click="sendNotif()">Enviar</v-btn>
            <v-btn v-if="editNotif" class="ma-5 mr-10" dark @click="sendEditNotif()">Actualizar</v-btn>
          </v-row>
          <v-row>
            <v-col class="ma-5">
              <v-text-field solo class="mb-1" v-model="notif.title" label="Título" 
                    :rules="requiredField"></v-text-field>
              <v-textarea solo class="mb-5" v-model="notif.description" label="Descripción" rows="5"
                    :rules="requiredField"></v-textarea>
              <v-row v-if="auto" style="background-color: whitesmoke; border-radius: 4px;" class="ml-0 mr-0">
                <v-col><PickerDate @event_date="setNotifDate($event)"></PickerDate></v-col>
                <v-col><PickerTime @event_hour="setNotifHour($event)"></PickerTime></v-col>
              </v-row>  
            </v-col>
          </v-row>
          <v-row>
            <h2 class="ml-2 mb-4" style="color: whitesmoke;">Previsualización:</h2>
          </v-row>
          <v-row class="rounded-xl" style="background-color: rgb(164, 164, 164);">
            <v-img class="ma-5 rounded-xl" src="../../assets/logo_theok.png" max-width="90"></v-img>
            <v-col>
              <h3 class="mt-6">{{ notif.title }}</h3>
              <p class="mt-1">{{ notif.description }}</p>
            </v-col>
          </v-row>
        </v-form>        
      </v-col>
    </v-container>
  </template>

  <script>

  import NotificationRepository from "../../repositories/NotificationRepository";
  import PickerDate from "../events/PickerDate.vue"
  import PickerTime from "../events/PickerTime.vue"

  export default {

    data() {
      return {
        notif: {
          event_id: "",
          user_id: 1,
          //"activity_id": "",
          //scheduled_date: "",
          //"sent": "", 
          title: "",
          description: "",
        },
        body: {
          user_id: 1,
        },
        editNotif: false,
        auto: false,
        date: null,
        hour: null,
        requiredField: [(v) => !!v || "Este campo es obligatorio"],
        hourRules: [(v) => (v >= 0)|| "Hora no válida", (v) => (v <= 24)|| "Hora no válida", (v) => !!v || "Este campo es obligatorio"],
        minRules: [(v) => (v >= 0)|| "Minuto no válido", (v) => (v <= 60)|| "Minuto no válido", (v) => !!v || "Este campo es obligatorio"],
      };
    },
    components: { PickerDate, PickerTime },

    async mounted() {
      this.notif.event_id = this.$route.params.event_id
      // console.log(this.notif.event_id)
    },

    methods: {

      setNotifDate(date) {
        this.date = date
      },

      setNotifHour(hour) {
        this.hour = hour
      },

      async sendNotif() {

        //Comprobamos la validez de los datos
        if (!this.$refs.form.validate()) {
          return;
        }

        if(this.date != null && this.hour != null) {
          this.notif.scheduled_date = this.date+' '+this.hour
        }
        
        try {
          await NotificationRepository.sendNotification(this.notif);
          this.goToNotificationsHistory()
        } catch (err) {
          this.$notify({
            text: err.response.data.message,
            type: "error",
          });
        }
        this.$refs.form.reset()
        this.auto = false
      },

      async sendEditNotif() {

        //Comprobamos la validez de los datos
        if (!this.$refs.form.validate()) {
          return;
        }

        this.notif.scheduled_date = this.date+' '+this.hour

        try {
          await NotificationRepository.sendEditNotification(this.notif);
        } catch (err) {
          this.$notify({
            text: err.response.data.message,
            type: "error",
          });
        }
        this.$refs.form.reset()
        this.auto = false
      },

      async goToHome() {
        this.$router.replace({
            name: "Home"
          });
      },

    goToNotificationsHistory() {
      // console.log("ENTRAAA")
      this.$router.push({ name: "NotificationList" });
    },

    },
  };
  </script>
