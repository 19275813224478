import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/common/auth";
// import store from "@/common/store";
import { NotFound, Login, Home, ControlBoard } from "@/components";
import postRouter from "@/entities/entities_router.js";
// import { saveLocalData } from '../common/localStorage';

// const user = store.state.user;

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { public: true, isLoginPage: true },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { public: true },
  },
  {
    path: "/control_board",
    name: "ControlBoard",
    component: ControlBoard,
    meta: { public: false },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { public: false },
  },
  { path: "*", component: NotFound, meta: { public: true } },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: routes.concat(postRouter),
});

router.beforeEach((to, from, next) => {

  auth.isAuthenticationChecked.finally(() => {

    // TO BATMAN DAY 2023
    const requiresAuth = !to.meta.public;
    const userIsLogged = auth.getLogged();

    if (requiresAuth) {
      if (userIsLogged) {
        next();
      } else {
        auth.removeLogged()
        next({ name: "Login" });
      }
    } else {
      if (to.meta.isLoginPage) {
        next();
      } else {
        next({ name: "Login" });
      }
    }
  });
});

/*
router.beforeEach((to, from, next) => {

  auth.isAuthenticationChecked.finally(() => {

    const requiresAuth = !to.meta.public;
    const requiredAuthority = to.meta.authority;
    const loggedUserAuthority = user.authority;
    // console.log(loggedUserAuthority)
    
    // TO BATMAN DAY 2023
    // const userIsLogged = user.logged;
    const userIsLogged = auth.getLogged();

    if (requiresAuth) {
      if (userIsLogged) {
        // privada  
        if (requiredAuthority) {
          // console.log("NEXT 0", next)
          // usuario logueado pero sin permisos suficientes, le redirigimos a la página de login
          Vue.notify({
            text: "Access is not allowed for the current user. Try to log again.",
            type: "error",
          });
          auth.logout();
          next("/login");
        } else {
          // console.log("NEXT 4", next)
          // usuario logueado y con permisos adecuados
          next();
        }
      } else {
        // usuario no está logueado, no puede acceder a la página
        Vue.notify({
          text: "This page requires authentication.",
          type: "error",
        });
        next();
      }
    } else {
      // pública
      if (userIsLogged && to.meta.isLoginPage) {
        next();
      } else {
        next({ name: "Users", replace: true });
      }
    }
  });
});
*/
export default router;
