<template>
  <div v-if="event" class="bar-container">
    <div>
      <v-row class="mt-10">
        <v-spacer/><div class="text-h3" style="color: white">{{ event.event_name }} </div><v-spacer/>
      </v-row>
      <v-row class="mt-10">
          <div class="text-body-1" style="color: white"> {{ event.description }} </div>
      </v-row>
      <v-row class="mt-10">
        <v-icon class="mr-5" dark>info_outline</v-icon>
        <div class="text-body-1" style="color: coral"> {{ event.information }} </div>
      </v-row>
      <v-row class="mt-10">
        <v-icon class="mr-5" dark>link</v-icon>
        <div><a :href="event.url" target="_blank">{{ event.url }}</a></div>
      </v-row>
      <v-row class="mt-10">
        <v-icon class="mr-5" dark>place</v-icon>
        <h4 style="color: white;">
          {{event.place}}, {{event.townhall_name}}, {{event.province_name}}, 
          {{event.ccaa_name}}, {{event.country}}</h4>
      </v-row>
      <v-row class="pa-10">
        <v-spacer/>
        <div style="width: 50%;">
          <v-img ref="img" :src="image"></v-img>
        </div>
        <v-spacer/>
      </v-row>           
    </div>

    <div class="bottom-bar">
      <div class="bar">
        <v-btn class="btn-bar" icon dark @click="sendNotification" title="Enviar notificación">
          <v-icon>mdi-bell-ring-outline</v-icon></v-btn>
        <v-btn class="btn-bar" icon dark @click="editEvent" title="Editar evento">
          <v-icon>mdi-pencil</v-icon></v-btn>    
        <v-btn class="btn-bar" icon dark @click="goToActivityList" title="Actividades">
          <v-icon>event</v-icon></v-btn>
        <v-btn class="btn-bar" icon dark @click="goToPackList" title="Packs disponibles">
          <v-icon>mdi-package-variant</v-icon></v-btn>
        <v-dialog v-model="delete_dialog" width="400">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="btn-bar" icon color="red" title="Eliminar evento">
                  <v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <v-card>
            <v-col class="pa-8">
              <v-row>
                <v-spacer/>
                <span class="headline">Borrar evento</span>
                <v-spacer/>
              </v-row>
              <v-row>
                <p class="pt-4">Si continúa, se borrará difinitivamente el evento.</p>
              </v-row>
              <v-row>
                <v-spacer/>
                <v-btn class="ma-2" text @click="delete_dialog = false" title="Eliminar">Cacelar</v-btn>
                <v-spacer/>
                <v-btn class="ma-2" dark @click="deleteEvent" title="Eliminar">Eliminar</v-btn>
                <v-spacer/>
              </v-row> 
            </v-col>
          </v-card> 
        </v-dialog>
      </div>
    </div>

  </div>
</template>

<script>
import EventRepository from "@/repositories/EventRepository";
import store from "../../common/store";

export default {
    data() {
        return {
            user: store.state.user,
            event: null,
            image: null,
            activities: [],
            suscibed: null,
            dialog: false,
            delete_dialog: false,
            web: "https://www.google.es",
            body: {
                user_id: 1
            },
            items: [
                { src: require("@/assets/logo_theok.png"), alt: "theok" },
                { src: require("@/assets/logo_theok.png"), alt: "theok" },
            ]
        };
    },

    async mounted() {
        try {
            this.event = await EventRepository.findEventById(this.$route.params.id);
            this.image = await EventRepository.getImageByEventId(this.$route.params.id);
            this.items.unshift({ src: this.image, alt: "front_image" });
        }
        catch (e) {
            this.$notify({
                type: "error",
            });
        }
    },


    methods: {

      closeDialog() {
        this.dialog = false
      },
      
      async deleteEvent() {
        EventRepository.deleteEvent(this.body, this.$route.params.id)
        this.$router.replace({ name: "EventList" })
      },

      async sendNotification() {
          this.$router.push({ name: "SendNotification", params: { event_id: this.event.id } });
      },

      async editEvent() {
          this.$router.push({ name: "EditEvent", params: { event_id: this.event.event_id } });
      },

      goToActivityList() {
          this.$router.push({ name: "ActivityList", params: { id: this.event.event_id } });
      },

      goToPackList() {
        this.$router.push({ name: "PackList", params: { id: this.event.event_id } });
      },

      back() {
          this.$router.go(-1);
      },
  },

};
</script>

<style>

.bar-container {
  margin-left: 13.5%;
  margin-bottom: 7%;
}

.bottom-bar {
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: 40px;
  right: 3%;
  display: flex;
  justify-content: right;
}

.bar {
  background-color: rgb(37, 37, 37);
  border: 1px solid #c9c9c9;
  height: 70px;
  border-radius: 10px;
  padding: 10px;
}

.btn-bar {
  margin: 6px;
}

</style>