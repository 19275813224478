<template>
  <div class="container">
    <v-data-table class="mr-5" dark :headers="headers" :items="events"
          @click:row="eventDetail"
          :items-per-page="10"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }">
          <template v-slot:items="props">
            <td @click="eventDetail(props.item)"></td>
          </template>
      <template v-slot:item.event_name="{ item }" dark>
        <h2 style="white-space: nowrap;"> {{ item.event_name }} </h2>
      </template>
      <template v-slot:item.start_date="{ item }" dark>
        <v-chip style="background-color: rgb(177, 220, 113)">
          <h4 style="color: black;">{{ getDate(item.start_date) }} </h4>
        </v-chip>
      </template>
      <template v-slot:item.end_date="{ item }" dark>
        <v-chip style="background-color: rgb(220, 113, 113)">
          <h4 style="color: black;">{{ getDate(item.end_date) }} </h4>
        </v-chip>
      </template>
      <template v-slot:item.absolute_place="{ item }" dark>
        <h4 style="white-space: nowrap;"> {{ getAbsolutePlace(item) }} </h4>
      </template>
      </v-data-table> 
  </div>
</template>

<script>

import EventRepository from "@/repositories/EventRepository";
import moment from "moment";

export default {
  data() {
    return {
      events: [],
      headers: [
          { text: 'Nombre', value: 'event_name' },
          //{ text: 'Descripción', value: 'description' },
          { text: 'Fecha inicio', value: 'start_date' },
          { text: 'Fecha fin', value: 'end_date' },
          { text: 'Ubicación', value: 'absolute_place' },
        ],
    };
  },

  mounted() {
    this.fetchData();
  },

  created() {
    this.fetchData();
  },

  methods: {

    async fetchData() {
      this.events = await EventRepository.findAllEvents();
      // console.log(this.events)
    },

    getDate(value) {
      const date = new Date(value * 1000)
      return moment(String(date)).locale('es').format('DD/MM/yyyy')
    },

    getAbsolutePlace(event) {
      return `${event.place}, ${event.townhall_name}, ${event.province_name}, ${event.ccaa_name}, ${event.country_name}`
    },

    eventDetail(event) {
      this.$router.push({name: 'EventDetail', params: { id: event.event_id }});
    },

  },
};
</script>

<style>

</style>
