<template>
  <v-container>
      <v-row class="justify-center mt-5 mb-10" style="align-self: baseline;">
        <img src="../assets/bottom.svg" @click="goToLogin()">    
      </v-row>
      <v-row style="background-color: white;">
        <h3 class="ml-6">EVENTOS</h3>
      </v-row>
      <v-row>
        <v-btn dark class="ma-5" @click="goToEventList">Eventos</v-btn>
        <v-btn dark class="ma-5" @click="goToNewEvent">Nuevo evento</v-btn>
      </v-row>
      <v-row style="background-color: white;">
        <h3 class="ml-6">ITEMS</h3>
      </v-row>
      <v-row>
        <v-btn dark class="ma-5" @click="goToNewItem">Nuevo item</v-btn>
      </v-row>
      <v-row style="background-color: white;">
        <h3 class="ml-6">NOTIFICACIONES</h3>
      </v-row>
      <v-row>
        <v-btn dark class="ma-5" @click="goToNotificationsHistory">Histórico de notificaciones</v-btn>
      </v-row>
  </v-container>
</template>

<script>
export default {

  data() {
    return {
    };
  },


  mounted() {
    
  },

  computed: {

  },


  methods: {

    goToEventList() {
        this.$router.push({ name: "EventList" });
    },

    goToNotificationsHistory() {
        this.$router.push({ name: "NotificationList" });
    },

    goToHome() {
        this.$router.push({ name: "Home" });
    },

    goToLogin() {
        this.$router.push({ name: "Login" });
    },

    goToNewEvent() {
        this.$router.push({ name: "CreateEvent" });
    },

    goToNewItem() {
        this.$router.push({ name: "CreateItem" });
    },

    goToNewPack() {
        this.$router.push({ name: "CreatePack" });
    },

    goBack() {
        this.$router.go(-1);
    }

  },
};
</script>

<style scoped>
</style>
