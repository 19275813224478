<template>
  <div class="bar-container" :style="{ width: var_width+'%' }">
    <v-col v-if="items">
      <v-data-table dark :headers="headers" :items="items"
            :items-per-page="7" :sort-by="'name'" :sort-desc="false"
            :footer-props="{ itemsPerPageOptions: [7, 14, 21] }">
        <template v-slot:item.image="{ item }">
          <img class="ma-2 mr-6" style="height: 40px; width: 40px;" :src="item.image"/>
        </template>
        <template v-slot:item.item_type="{ item }" dark>
            {{ getItemType(item.item_type) }}
        </template>
        <template v-slot:item.delete_item="{ item }">
          <v-icon @click="deleteItem(item)">delete_outline</v-icon>
        </template>
        <template v-slot:item.edit_item="{ item }">
          <v-icon @click="editItem(item)">edit</v-icon>
        </template>
      </v-data-table>        
    </v-col>

    <div class="bottom-bar">
      <div class="bar">
        <v-btn class="ma-2" color="white" icon @click="openLateralBar()"><v-icon>add</v-icon></v-btn>
      </div>
    </div>

    <v-col v-show="create_item" class="fixed">
      <v-btn class="mt-2" icon color="white" @click="closeLateralBar()"><v-icon>close</v-icon></v-btn>
      <h3 class="mt-4" style="color: white; text-align: center;">NUEVO ITEM</h3>
      <v-form class="ml-10 mr-10 mt-4" ref="form" @submit.prevent="">
          <v-text-field dark outlined v-model="name" label="Nombre"
                :rules="requiredField"></v-text-field>
          <v-textarea dark outlined v-model="description" label="Descripción" rows="3"
                :rules="requiredField"></v-textarea>
          <v-select dark outlined v-model="item_type" :items="items_types" item-text="name" label="Tipo" 
                return-value="value" :rules="requiredField"></v-select> 
      </v-form>
      <div class="image-uploader" @dragover.prevent @drop="handleDrop" @click="openFileSelector">
        <input ref="fileInput" type="file" style="display: none" @change="handleFileChange" accept="image/png"/>
        <p v-if="!image" style="color: white;">Arrastra y suelta o haz clic para seleccionar una imagen</p>
        <div v-if="image"> 
          <img style="height: 120px;" :src="image"/>
        </div>
      </div>
      <v-row class="mb-2 mt-2">
        <v-spacer/>
        <v-icon v-if="image" style="color: white;" @click="deleteImage()">delete</v-icon>
        <v-spacer/>
      </v-row>
      <v-row class="mb-2">
        <v-spacer/>
        <v-btn dark type="submit" @click="saveItem()">Guardar</v-btn>
        <v-spacer/>
      </v-row>         
    </v-col> 
  </div>
</template>


<script>

import moment from "moment";
import ItemRepository from "@/repositories/ItemRepository";

export default {

  data() {
    return {
      items_types: [
        { name: 'Comida', value: 'food' },
        { name: 'Bebida', value: 'drink' },
        { name: 'Entrada', value: 'ticket' }
      ],
      item: {
        id: null,
        name: "",
        description: "",
        item_type: "",
        image: ""
      },
      name: "",
      description: "",
      item_type: "",
      image: "",
      create_item: false,
      var_width: 100,
      headers: [
          { text: 'Nombre', value: 'name'},
          { text: 'Imagen', value: 'image'},
          { text: 'Descripción', value: 'description'},
          { text: 'Tipo', value: 'item_type'},
          { text: 'Editar', value: 'edit_item', align: "center" },
          { text: 'Eliminar', value: 'delete_item', align: "center" },
        ],
      items: [],
      requiredField: [(v) => !!v || "Este campo es obligatorio"],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {

    async fetchData() {
      try {
        this.items = await ItemRepository.findAllItems()
        this.items.slice().sort((a, b) => a.name - b.name);
        this.setImage()
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    setImage() {
      this.items.map(item => {
        this.getImage(item)
      });
    },

    async getImage(item) {
      try {
        const img = await ItemRepository.getImageItem(item.id)
        item.image = img
        this.items.splice(this.items.findIndex(x => x === item), 1, item)
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    async saveItem() {
      //Comprobamos la validez de los datos
      if (!this.$refs.form.validate()) {
        return;
      }

      this.setData()

      try {

        if(this.item.id == null) {
          await ItemRepository.addNewItem(this.item)
          this.items.push(this.item)
        } else {
          await ItemRepository.updateItem(this.item)
          this.items.splice(this.items.findIndex(x => x === this.item), 1, this.item)
        }
        this.$refs.form.reset()
        this.image = ""
        setTimeout(() => { this.fetchData() }, 5000)
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    setData() {
      this.item.name = this.name
      this.item.description = this.description
      this.item.item_type = this.item_type
      this.item.image = this.image
    },

    async deleteItem(item) {      
      try {
        await ItemRepository.deleteItem(item.id)
        this.items.splice(this.items.indexOf(item), 1)
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    openLateralBar() {
      this.create_item = true
      this.var_width = 50
    },

    closeLateralBar() {
      this.create_item = false
      this.var_width = 100
    },

    async editItem(item) {
      this.openLateralBar()
      this.item = item
      this.name = this.item.name
      this.description = this.item.description
      this.item_type = this.item.item_type
      this.image = this.item.image
    },

    getItemType(type) {
      switch (type) {
        case "drink" : return "Bebida"
        case "food" : return "Comida"
        case "ticket" : return "Entrada"
      }
    },

    back() {
      this.$router.go(-1);
    },

    formatDate(value) {
      return moment(value).format('yyyy-MM-DD')
    },

    formatHour(value) {
      return moment(value).format('HH:mm')
    },

    deleteImage() {
      this.image = null
      this.formData = null
    },

    handleDrop(event) {
      event.preventDefault();
      this.loadImage(event.dataTransfer.files[0]);
    },

    openFileSelector() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      this.loadImage(event.target.files[0]);
    },

    loadImage(file) {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          var str = reader.result.replace("jpeg", "png")
          str = reader.result.replace("jpg", "png")
          this.image = str
        }
      }
    }

  },
};
</script>

<style>

.fixed {
  position: fixed;
  top: 50px;
  right: 0;
  width: 390px;
  height: 100%;
  align-items: center;
  background-color: rgb(37, 37, 37);
}

.image-uploader {
  border: 2px dashed #ccc;
  padding: 10px;
  text-align: center;
  margin: 20px;
}

</style>
