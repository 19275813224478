<template>
  <div class="container">
    <v-row class="mt-5 mb-10">
      <v-spacer/>
      <h3 style="color: white;">PACKS DISPONIBLES</h3>
      <v-spacer/>
    </v-row>
    <v-data-table dark :headers="headers" :items="packs"
          :items-per-page="10"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }">
      <template v-slot:item.pack_detail="{ item }">
        <v-icon  @click="packDetail(item)">manage_search</v-icon>
      </template>
      <template v-slot:item.delete_pack="{ item }">
        <v-icon @click="deletePack(item)">delete_outline</v-icon>
      </template>
      <template v-slot:item.edit_pack="{ item }">
        <v-icon @click="editPack(item.id)">edit</v-icon>
      </template>
    </v-data-table>  
    <v-row class="ma-5">
      <v-spacer/>
      <v-btn @click="goToNewPack()"><v-icon>add</v-icon></v-btn>
    </v-row>      
  </div>
</template>


<script>

import PackRepository from "../../repositories/PackRepository";

export default {

  data() {
    return {
      headers: [
          { text: 'Nombre', value: 'name'},
          { text: 'Descripción', value: 'description'},
          { text: 'Precio (€)', value: 'price' },
          { text: 'Stock total', value: 'total_stock'},
          { text: 'Stock actual', value: 'current_stock' },
          { text: 'Items', value: 'pack_detail', align: "center" },
          { text: 'Editar', value: 'edit_pack', align: "center" },
          { text: 'Eliminar', value: 'delete_pack', align: "center" }
        ],
      packs: [],
      requiredField: [(v) => !!v || "Este campo es obligatorio"],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {

    async fetchData() {
      this.packs = await PackRepository.findEventPacks(this.$route.params.id);
      // console.log(this.packs)
    },

    getItemType(type) {
      switch (type) {
        case "drink" : return "Bebida"
        case "food" : return "Comida"
        case "ticket" : return "Entrada"
      }
    },

    goToNewPack() {
      this.$router.push({ name: "CreatePack", params: { event_id: this.$route.params.id } });
    },

    async deletePack(pack) {      
      try {
        await PackRepository.deletePack(pack.event_id, pack.id);
        this.fetchData()
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    editPack(pack_id) {
      this.$router.push({ name: "EditPack", params: { event_id: this.$route.params.id, pack_id: pack_id} });
    },

    packDetail(pack) {
      this.$router.push({ name: "AddItemsToPack", params: { event_id: this.$route.params.id, pack_id: pack.id} });
    },

  },
};
</script>

<style>

</style>