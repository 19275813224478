<template>
  <div class="pa-10">
    <v-form ref="form" @submit.prevent="">
      <v-text-field dark outlined v-model="evento.event_name" label="Nombre del evento"
            :rules="requiredField"></v-text-field>
      <v-textarea dark outlined v-model="evento.description" label="Descripción del evento" rows="5" 
            :rules="requiredField"></v-textarea>
            <v-row class="date-view">
        <div class="ma-5">
          <p>Fecha de inicio:</p>
          <input class="mr-15" type="datetime-local" v-model="start_date" required/>
        </div>
        <div class="ma-5">
          <p>Fecha de inicio:</p>
          <input class="mr-15" type="datetime-local" v-model="end_date" required/>         
        </div>
      </v-row>       
      <v-row>
        <v-col>
          <v-select dark outlined v-model="evento.country_name" :items="countries" item-text="name" label="País" return-object 
                :rules="requiredField"></v-select>              
        </v-col>    
        <v-col>
          <v-select dark outlined v-model="ca" :items="ccaa" item-text="name" label="CA" return-object 
                @change="selectCA()" :rules="requiredField"></v-select>            
        </v-col>
        <v-col>
          <v-select dark outlined v-model="province" :items="provinces" item-text="name" label="Provincia" return-object 
                @change="selectProvince()" :rules="requiredField"></v-select>            
        </v-col>
        <v-col>
          <v-select dark outlined v-model="townhall" :items="townhalls" item-text="name" label="Ayuntamiento" return-object 
                :rules="requiredField"></v-select>            
        </v-col> 
        <v-col>
          <v-text-field dark outlined v-model="evento.place" label="Lugar del evento"
                :rules="requiredField"></v-text-field>     
        </v-col>
      </v-row>
      <v-row>
        <v-spacer/>
        <v-col>
          <input dark type="file" accept="image/png, image/jpg" @change="onFileSelected">
        </v-col>
        <v-spacer/>
      </v-row>
      <v-row>
        <v-spacer/>
        <v-col><img style="width: 100%;" :src="evento.image"></v-col>
        <v-spacer/>
      </v-row>
      <v-row class="pa-5">
        <v-spacer/>
        <v-btn @click="saveEvent()">Guardar</v-btn>
      </v-row>
    </v-form>        
  </div>
</template>

<script>
import EventRepository from "@/repositories/EventRepository"
import LocationsRepository from "../../repositories/LocationsRepository"

export default {

  data() {
    return {
      ccaa: [],
      provinces: [],
      townhalls: [],
      countries: ["España"],
      ca: null,
      province: null,
      townhall: null,
      evento: {
        event_name: "",
        description: "",
        start_date: 0,
        end_date: 0,
        place: "",
        townhall_id: 27,
        townhall_name: "Townhall",
        province_id: null,
        province_name: "Province",
        ccaa_id: null,
        ccaa_name: "CCAA",
        country_id: null,
        country_name: "España",
        image: null,
      },
      start_date: "",
      end_date: "",
      selectImage: "",
      formData: null,
      requiredField: [(v) => !!v || "Este campo es obligatorio"],
      editView: false,
    };
  },

  async mounted() {
    if(this.$route.params.event_id) {
      this.fetchData()
      this.editView = true
    }
  },

  methods: {

    async fetchData() {
      try {
        this.evento = await EventRepository.findEventById(this.$route.params.event_id)
        this.evento.image = await EventRepository.getImageByEventId(this.$route.params.event_id)
        // Fechas del evento +2h local
        this.start_date = this.secondsToDate(this.evento.start_date + 7200)
        this.end_date = this.secondsToDate(this.evento.end_date + 7200)
      } catch (e) {
        this.$notify({
          type: "error",
        });
      }
    },

    async selectCA() {
      this.provinces = await LocationsRepository.getProvincesByCA(this.ca.parent_id)
      this.provinces = Object.keys(this.provinces).map((key) => this.provinces[key])
      // console.log(this.province)
    },

    async selectProvince() {
      this.townhalls = await LocationsRepository.getTownhallsByProvince(this.province.id)
    },

    setDates() {
      this.start_date = this.formatDate(this.evento.start_date)
      this.start_hour = this.formatHour(this.evento.start_date)
      this.end_date = this.formatDate(this.evento.end_date)
      this.end_hour = this.formatHour(this.evento.end_date)
    },

    deleteImage() {
      this.selectImage = null
      this.formData = null
    },

    async saveEvent() {

      //Comprobamos la validez de los datos
      if (!this.$refs.form.validate()) {
        return;
      }
      

      this.evento.image = this.selectImage
      this.evento.start_date = this.stringToSeconds(this.start_date)
      this.evento.end_date = this.stringToSeconds(this.end_date)

      try {
        if (this.editView) {
          await EventRepository.updateEvent(this.evento, this.$route.params.event_id)
        } else {
          await EventRepository.addNewEvent(this.evento);
        }
        this.goToHome()
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    async goToHome() {
      this.$router.replace({name: "EventList"});
    },

    onFileSelected(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.evento.image.push = reader.result
      }
    },

    secondsToDate(seconds) {
      const dateObject = new Date(seconds * 1000);
      return dateObject.toISOString().slice(0, -8);
    },

    stringToSeconds(value) {
      const date = new Date(value)
      return date.getTime()/1000
    },

  },

};
</script>
