<template>
  <div class="container">
    <h3 style="color: white; text-align: center;">AÑADIR ITEMS AL PACK</h3>

    <v-form class="ma-2" ref="form" @submit.prevent="">
      <v-row class="mt-4">
          <v-select dark outlined v-model="new_item" :items="items" item-text="name" label="Item" return-object 
                :rules="requiredField" style="width: 70%;" ></v-select>
        <v-icon class="ma-4 mb-10" style="color: white;" @click="decrement">remove_circle_outline</v-icon>
        <v-text-field dark type="number" v-model.number="quantity" :rules="greatThanZero"/>
        <v-icon class="ma-4 mb-10" style="color: white;" @click="increment">add_circle_outline</v-icon>
      </v-row>
    </v-form>
    <v-row class="mb-10" style="justify-content: center;">
      <v-btn dark type="submit" @click="itemToPack()">Añadir</v-btn>
    </v-row>

    <h3 class="ma-4" style="color: white; text-align: center;">{{ pack.name }}</h3>
    <v-data-table class="ma-5" dark :headers="headers" :items="items_pack"
          :items-per-page="10" :sort-by="'name'" :sort-desc="false"
          :footer-props="{ itemsPerPageOptions: [5, 5] }">
      <template v-slot:item.item_type="{ item }" dark>
          {{ getItemType(item.item_type) }}
      </template>
      <template v-slot:item.delete_item="{ item }">
        <v-icon @click="deleteItem(item)">delete_outline</v-icon>
      </template>
    </v-data-table>   
  </div>
</template>


<script>

import ItemRepository from "@/repositories/ItemRepository";
import PackRepository from "../../repositories/PackRepository";

export default {

  data() {
    return {
      items_types: [
        { name: 'Comida', value: 'food' },
        { name: 'Bebida', value: 'drink' },
        { name: 'Entrada', value: 'ticket' }
      ],
      pack: null,
      new_item: null,
      quantity: 0,
      body: {
        item_id: null,
        quantity: null
      },
      items: [],
      items_pack: [],
      headers: [
          //{ text: 'Nombre', value: 'name'},
          { text: 'ID', value: 'item_id'},
          { text: 'Cantidad', value: 'quantity'},
          //{ text: 'Descripción', value: 'description'},
          //{ text: 'Tipo', value: 'item_type'},
          { text: 'Eliminar del pack', value: 'delete_item', align: "center"},
        ],
      requiredField: [(v) => !!v || "Este campo es obligatorio"],
      greatThanZero: [(v) => (!!v || v>0) || "La cantidad debe ser mayor de 0"],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {

    async fetchData() {
      this.getItems()
      this.getPack()
    },

    async getPack() {
      try {
        this.pack = await PackRepository.getPack(this.$route.params.event_id, this.$route.params.pack_id)
        this.items_pack = this.pack.items
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    async getItems() {
      try {
        this.items = await ItemRepository.findAllItems()
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },


    async itemToPack() {
      
      //Comprobamos la validez de los datos
      if (!this.$refs.form.validate()) {
        return;
      }

      this.body.item_id = this.new_item.id
      this.body.quantity = this.quantity

      try {
        PackRepository.addItemToPack(this.pack.event_id, this.pack.id, this.body)
        this.$refs.form.reset()
        setTimeout(() => { this.fetchData() }, 500)
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },


    async deleteItem(item) {
      try {
        await PackRepository.removeItemPack(this.pack.event_id, this.pack.id, item.item_id)
        this.items_pack.splice(this.items_pack.indexOf(item), 1)
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    increment() {
      this.quantity += 1
    },

    decrement() {
      if (this.quantity > 0) {
        this.quantity -= 1
      }
    },

    getItemType(type) {
      switch (type) {
        case "drink" : return "Bebida"
        case "food" : return "Comida"
        case "ticket" : return "Entrada"
      }
    },
  },
};
</script>

<style>

</style>
