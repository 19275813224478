<template>
  <v-container>
    <v-row class="justify-center">
      <v-col>
        <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dark outlined v-model="date" prepend-icon="mdi-calendar" readonly
                  label="Fecha" v-bind="attrs" v-on="on" ></v-text-field>
          </template>
          <v-date-picker color="black" v-model="date" scrollable :format='"DD/MM/yyyy"' :first-day-of-week="1"
                @change="$refs.dialog.save(date); setDate();"></v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import moment from "moment"

  export default {

    props: {
      init_date: null, 
    },

    data: () => ({
      date: null,
      fotmatDate: null,
      modal: false,
    }),

    mounted() {
      if (this.init_date) {
        this.date = this.formatDate(this.init_date)
      }
    },

    methods: {
      setDate() {
        this.modal = false
        this.$emit('event_date', this.date);
      },

      formatDate(value) {
        const date = new Date(value * 1000)
        return (moment(String(date)).locale('es').format('yyyy-MM-DD'))
      },
    },
    


  }
</script>

<style scoped>
</style>
