<template>
  <div class="container-users">
    <v-row class="mt-5">
      <v-spacer/>
      <!-- <h3 style="color: white;">USUARIOS</h3> -->
      <h1 style="color: orangered;">{{ numUsers }}</h1> 
      <h2  class="pl-2 pt-2" style="color: white;"> ASISTENTES</h2>
      <v-spacer/>
    </v-row>
    <v-row class="pl-4 pr-2" style="justify-content: right; align-items: center;">
      <v-col cols="3">
        <v-select dark v-model="filter" :items="options"
              label="Filtro"
              item-text="name"
              item-value="value"
              @change="filterAction()">
        </v-select>
      </v-col>
      <v-btn icon color="red" @click="cleanFilters()" title="Limpiar filtros">
          <v-icon>cleaning_services</v-icon></v-btn>
    </v-row>
    
    <v-data-table dark :headers="headers" :items="filter_users"
          :items-per-page="5"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100, -1] }">

      <!-- TO BATMAN DAY 2023-->
      <template v-slot:item.orden="{ item, index }">
        <h3>{{ index + 1 }}</h3>
      </template>
      <template v-slot:item.name="{ item }">
        <h3>{{ item.name }}</h3>
      </template>
      <template v-slot:item.surname="{ item }">
        <h3>{{ item.surname }}</h3>
      </template>
      <template v-slot:item.email="{ item }">
        <h3>{{ item.email }}</h3>
      </template>
      <template v-slot:item.email_sent="{ item }">
        <v-chip :color="getColor(item.email_sent)">{{ sentEmail(item.email_sent) }}</v-chip>
      </template>
      <template v-slot:item.unresolvable_email="{ item }">
        <v-chip :color="getColor(item.email_sent)">{{ invalidEmail(item.unresolvable_email) }}</v-chip>
      </template>
      <!---->


      <template v-slot:item.rol="{ item }">
        <h4>{{ getUserRol(item.rol) }}</h4>
      </template>
      <template v-slot:item.delete_pack="{ item }">
        <v-icon @click="deletePack(item)">delete_outline</v-icon>
      </template>
      <template v-slot:item.edit_pack="{ item }">
        <v-icon @click="editPack(item.id)">edit</v-icon>
      </template>
      <template v-slot:item.user_detail="{ item }">
        <v-icon @click="goToUserDetail(item.id)">search</v-icon>
      </template>
    </v-data-table>

    <input type="file" ref="fileInput" style="display: none" />

    <v-row class="pa-10" style="justify-content: right; align-items: center;">
      <v-btn color="white" @click="exportToExcel()">Exportar a archivo .xlsx</v-btn>     
    </v-row>

    <!--
    <div class="bottom-bar">
      <div class="bar">
        <v-btn class="ma-2" color="white" icon @click="goToNewUser()"><v-icon>add</v-icon></v-btn>
      </div>
    </div>      
    -->

  </div>
</template>


<script>

import UserRepository from "../../repositories/UserRepository"
import XLSX from 'xlsx';

export default {

  data() {
    return {
      headers: [
          { text: 'Orden', value: 'orden', align: 'center'},
          // { text: 'Username', value: 'username'},
          { text: 'Nombre', value: 'name'},
          { text: 'Apellidos', value: 'surname'},
          //{ text: 'DNI', value: 'dni' },
          //{ text: 'Teléfono', value: 'tlf'},
          { text: 'Email', value: 'email' },
          //{ text: 'Rol', value: 'rol'},
          //{ text: 'Detalle', value: 'user_detail', align: 'center'},
          { text: 'Email enviado', value: 'email_sent', align: 'center'},
          { text: 'Email', value: 'unresolvable_email', align: 'center'},
        ],
      filter: null,
      options: [
          { name: 'Emails válidos', value: 1},
          { name: 'Emails NO válidos', value: 2},
          { name: 'Emails enviados', value: 3},
          { name: 'Emails NO enviados', value: 4}
        ],
      users: [],
      filter_users: [],
      numUsers: null,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {

    async fetchData() {
      //this.users = await UserRepository.findAll()
      // TO BATMAN DAY 2023
      const obj = await UserRepository.getUsersBatmanDay()
      this.users = obj.users
      this.filter_users = this.users
      this.numUsers = obj.count
    },

    goToUserDetail(user_id) {
      this.$router.push({ name: "UserDetail", params: { user_id: user_id} });
    },

    filterAction() {
      if(this.filter == 1) {
        this.filter_users = this.users.filter(user => user.email_sent == 1)
      }
      if(this.filter == 2) {
        this.filter_users = this.users.filter(user => user.email_sent == 0)
      }
      if(this.filter == 3) {
        this.filter_users = this.users.filter(user => user.unresolvable_email == 0)
      }
      if(this.filter == 4) {
        this.filter_users = this.users.filter(user => user.unresolvable_email == 1)
      }
      this.numUsers = this.filter_users.length;
    },

    cleanFilters() {
      this.filter_users = this.users
      this.filter = null
      this.numUsers = this.filter_users.length;
    },

    getUserRol(rol) {
      switch (rol) {
        case "assitant" : return "Asistente"
        case "creator" : return "Creador"
        case "admin" : return "Administrador"
      }
    },


    // TO BATMAN DAY 2023

    sentEmail(bool) {
      if (bool == 1) {
        return 'OK'
      } else {
        return 'ERROR'
      }
    },

    invalidEmail(bool) {
      if (bool == 1) {
        return 'INVÁLIDO'
      } else {
        return 'VÁLIDO'
      }
    }, 

    getColor(bool) {
      if (bool == 1) {
        return 'green'
      } else {
        return 'red'
      }
    },

    exportToExcel() {
      const export_data = this.filter_users.map(item => ({ ... {
        Nombre: item.name, 
        Apellidos: item.surname,
        Correo: item.email,
        Enviado: (item.email_sent == 1) ? 'OK' : 'ERROR',
        Email_Válido: (item.unresolvable_email == 0) ? 'VÁLIDO' : 'NO VÁLIDO',
      }}))

      let data = XLSX.utils.json_to_sheet(export_data)
      const workbook = XLSX.utils.book_new()
      const filename = 'batman_day_registered'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
  },
};
</script>

<style>

.container-users {
  padding-top: 25px;
  padding-left: 100px;
  padding-right: 100px;
}

</style>