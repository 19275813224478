<template>
  <div class="container">
    <v-row class="mt-5 mb-10">
      <v-spacer/>
      <h3 style="color: white;">ACTIVIDADES</h3>
      <v-spacer/>
    </v-row>
    <v-data-table dark :headers="headers" :items="activities"
          :items-per-page="10"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }">
      <template v-slot:item.name="{ item }">
        <h3 class="ml-5"> {{ item.name }} </h3>
      </template>
      <template v-slot:item.start_date="{ item }">
        <h3 class="start-date"> {{ getTime(item.start_date) }} </h3>
      </template>
      <template v-slot:item.end_date="{ item }">
        <h3 class="end-date"> {{ getTime(item.end_date) }} </h3>
      </template>
      <template v-slot:item.delete_item="{ item }">
        <v-icon @click="deleteItem(item)">delete_outline</v-icon>
      </template>
      <template v-slot:item.edit_item="{ item }">
        <v-icon @click="editItem(item)">edit</v-icon>
      </template>
    </v-data-table>   

    <div class="bottom-bar">
      <div class="bar">
        <v-btn class="ma-2" color="white" icon @click="goToNewActivity"><v-icon>add</v-icon></v-btn>
      </div>
    </div>
    
  </div>
</template>


<script>

import ActivityRepository from "../../repositories/ActivityRepository";
import moment from "moment";

export default {

  data() {
    return {
      activities: [],
      headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Descripción', value: 'description' },
          { text: 'Hora inicio', value: 'start_date'},
          { text: 'Hora final', value: 'end_date'},
          { text: 'Lugar', value: 'place'},
          { text: 'Tipo', value: 'activity_type' },
          { text: 'Editar', value: 'edit_item', align: "center" },
          { text: 'Eliminar', value: 'delete_item', align: "center" }
        ],
      items: [],
      requiredField: [(v) => !!v || "Este campo es obligatorio"],
    };
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.activities = await ActivityRepository.findActivitiesByEventId(this.$route.params.id)
        // console.log(this.activities)
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    async goToEditActivity(activity_id) {
      this.$router.push({ name: "EditActivity", params: { activity_id: activity_id } });
    },

    async goToNewActivity() {
      this.$router.push({ name: "CreateActivity", params: { id: this.$route.params.id } });
    },


    async deleteItem(item) {      
      try {
        await ActivityRepository.deleteActivity(item.id);
        this.fetchData()
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

    getTime(value) {
      const date = new Date(value * 1000)
      return moment(String(date)).locale('es').format('HH:mm')
    },

    getDay(value) {
      const date = new Date(value * 1000)
      return moment(String(date)).locale('es').format('DD')
    },

    async editItem(item) {  
      const id = item.id    
      this.goToEditActivity(id)
    },

    back() {
      this.$router.go(-1);
    },

  },
};
</script>

<style>

.start-date {
  color: aquamarine;
}

.end-date {
  color: tomato;
}


</style>
