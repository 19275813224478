import HTTP from "@/common/http";

const resource = "notifications";

export default {

  async getNotifications() {
    let response = await HTTP.get(`${resource}`);
    return response.data.body
  },

  async sendNotification(notif) {
    return await HTTP.post(`${resource}`, notif).data;
  },

  async findNotificationById(body, id) {
    let response = await HTTP.get(`${resource}/${id}`, { data: { "user_id": 1 }});
    return response.data.body
  },

  async sendEditNotification(notif) {
    return await HTTP.put(`${resource}/${notif.id}`, notif).data;
  },

  async deleteNotification(id) {
    let response = await HTTP.delete(`${resource}/${id}`);
    return response.data.body
  },

};
