import { render, staticRenderFns } from "./EventDetail.vue?vue&type=template&id=5e6d4703&"
import script from "./EventDetail.vue?vue&type=script&lang=js&"
export * from "./EventDetail.vue?vue&type=script&lang=js&"
import style0 from "./EventDetail.vue?vue&type=style&index=0&id=5e6d4703&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports