import EventList from "./events/EventList"
import CreateEvent from "./events/CreateEditActivity"
import EventDetail from "./events/EventDetail"
import CreateEditEvent from "./events/CreateEditEvent"
import Pass from "./events/Pass"
import Club from "./events/Club"
import SendNotification from "./notifications/SendNotification"
import NotificationList from "./notifications/NotificationList"
import CreateEditActivity from "./events/CreateEditActivity"
import EditNotification from "./notifications/EditNotification"
import CreateItem from "./items/CreateItem"
import CreateEditPack from "./packs/CreateEditPack"
import PackList from "./packs/PackList"
import ActivityList from "./events/ActivityList"
import AddItemsToPack from "./items/AddItemsToPack"
import UserList from "./users/UserList"
import UserDetail from "./users/UserDetail"

const routes = [
  {
    name: "UserDetail",
    path: "/user/:user_id",
    component: UserDetail,
    meta: { public: true },
  },
  {
    name: "UserList",
    path: "/users",
    component: UserList,
    meta: { public: false },
  },
  {
    name: "AddItemsToPack",
    path: "/event/:event_id/pack/:pack_id/items",
    component: AddItemsToPack,
    meta: { public: true },
  },
  {
    name: "ActivityList",
    path: "/activities/:id",
    component: ActivityList,
    meta: { public: true },
  },
  {
    name: "PackList",
    path: "/packs/:id",
    component: PackList,
    meta: { public: true },
  },
  {
    name: "CreatePack",
    path: "/pack/create/:event_id",
    component: CreateEditPack,
    meta: { public: true },
  },
  {
    name: "EditPack",
    path: "event/:event_id/pack/edit/:pack_id",
    component: CreateEditPack,
    meta: { public: true },
  },
  {
    name: "CreateItem",
    path: "/item/create",
    component: CreateItem,
    meta: { public: true },
  },
  {
    name: "EditActivity",
    path: "/activities/edit/:activity_id",
    component: CreateEditActivity,
    meta: { public: true },
  },
  {
    name: "CreateActivity",
    path: "/activities/create/:id",
    component: CreateEditActivity,
    meta: { public: true },
  },
  {
    name: "NotificationList",
    path: "/notifications",
    component: NotificationList,
    meta: { public: true },
  },
  {
    name: "EditNotification",
    path: "/notifications/edit/:id",
    component: EditNotification,
    meta: { public: true },
  },
  {
    name: "SendNotification",
    path: "/notifications/send/:event_id",
    component: SendNotification,
    meta: { public: true },
  },
  {
    name: "Club",
    path: "/club",
    component: Club,
    meta: { public: true },
  },
  {
    name: "Pass",
    path: "/pass",
    component: Pass,
    meta: { public: true },
  },
  {
    name: "EventList",
    path: "/events",
    component: EventList,
    meta: { public: true },
  },
  {
    name: "CreateEvent",
    path: "/events/create",
    component: CreateEditEvent,
    meta: { public: true },
  },
  {
    name: "EditEvent",
    path: "/events/edit/:event_id",
    component: CreateEditEvent,
    meta: { public: true },
  },
  {
    name: "EventDetail",
    path: "/events/:id",
    component: EventDetail,
    meta: { public: true },
  },
  {
    name: "UpdateEvent",
    path: "/events/:id/update",
    component: CreateEvent,
  },
];

export default routes;
