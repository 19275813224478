<template>
  <div>
    <v-form @submit.prevent="userLogin">
      <v-row style="justify-content: center;">
        <img class="pt-15" style="width: 250px;" src="../assets/logo_theok.svg">
      </v-row>
      <v-row class="pt-5" style="justify-content: center;">
        <v-col cols="5">
          <v-text-field outlined dark label="Nombre de usuario" name="login" type="text" v-model="username"></v-text-field>
          <v-text-field outlined dark v-model="password" label="Contraseña" name="password" type="password"></v-text-field>              
        </v-col>  
      </v-row>
      <v-row style="justify-content: center;">
        <div v-show="login_error" class="alert alert-danger">
          Inicio de sesión fallido. Por favor, verifique sus credenciales.
        </div>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn @click="login()">Iniciar sesion</v-btn>
        <v-spacer></v-spacer>            
      </v-row>
    </v-form>        
  </div>
</template>

<script>

import auth from "../common/auth";
import { saveLocalData, getLocalData } from '../common/localStorage';

export default {
  data() {
    return {
      username: "",
      password: "",
      back_img: require("@/assets/logo_theok.png"),
      login_error: false,
    };
  },

  methods: {

    userLogin() {
      auth
        .login({
          login: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((err) => {
          this.$notify({
            text: err.response.data.message,
            type: "error",
          });
        });
    },

    goToMenu() {
      this.$router.push({ name: "UserList" })
    },
    

    login() {
      if (this.username == "dccomicsspain" && this.password == "5bsQ56zW") {
        this.login_error = false
        auth.loginBatman()
        this.goToMenu()
      } else {
        this.login_error = true
      }
    },

    saveUserLogged() {
      const data = { user_logged : true }
      saveLocalData('user_logged', data);
    },

    getData(key) {
      const data = getLocalData(key)
      if (data) {
        // console.log(' LOGIN Dato recuperado:', data);
        return data
      } else {
        // console.log('No hay datos almacenados.');
      }
    }

  },
};
</script>


<style>

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid;
  border-radius: 10px;
  border-color: #721c24;
  color: #721c24;
  background-color: #f8d7da;
}
.alert-danger {
  color: #842029;
  background-color: #f5c6cb;
}

</style>