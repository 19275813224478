<template>
  <v-container>
    <v-row class="justify-center">
      <v-col>
        <v-dialog ref="dialog" v-model="modal" :return-value.sync="time" persistent width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field dark outlined v-model="time" prepend-icon="access_time" readonly v-on="on" 
                label="Hora"></v-text-field>
        </template>
        <v-time-picker color="black" v-if="modal" v-model="time" format="24hr">
          <v-spacer></v-spacer>
          <v-btn dark @click="modal = false">Cancel</v-btn>
          <v-btn dark @click="$refs.dialog.save(time); setTime();">OK</v-btn>
        </v-time-picker>
      </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import moment from "moment"

  export default {

    props: {
      init_hour: null, 
    },

    data: () => ({
      time: null,
      modal: false,
    }),

    mounted() {
      if (this.init_hour) {
        this.time = this.formatDate(this.init_hour)
      }
    },

    methods: {
      setTime() {
        this.modal = false
        this.$emit('event_hour', this.time);
      },

      formatDate(value) {
        const date = new Date(value * 1000)
        return (moment(String(date)).locale('es').format('HH:mm'))
      },
    },
    


  }
</script>

<style scoped>
</style>
