import HTTP from "@/common/http";

export default {

  async findAll() {
    const response = await HTTP.get(`users`);
    return response.data.body;
  },

  async findUserById(user_id) {
    const response = await HTTP.get(`users/${user_id}`);
    return response.data.body;
  },



  // TO BATMAN DAY 2023
  async getUsersBatmanDay() {
    const response = await HTTP.get(`users`);
    // console.log(response)
    return response.data.body;
  },

};
