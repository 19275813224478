<template>
  <div class="container">
    <v-card dark class="user-card">
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <img class="user-photo" src="../../assets/fondo.jpg" />
          </v-col>
          <v-col class="ma-4 ml-8" cols="4">
            <p><strong>Nombre: </strong> {{ user.name }} </p>
            <p><strong>Apellidos:</strong> {{ user.surname }}</p>
            <p><strong>Nombre de usuario:</strong> {{ user.username }}</p>
            <p><strong>Email:</strong> {{ user.email }}</p>
            <p><strong>DNI:</strong> {{ user.dni }}</p>
            <p><strong>ROL:</strong> {{ getUserRol(user.rol) }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="bottom-bar">
      <div class="bar">
        <v-btn class="btn-bar" icon dark title="Suscripciones"><v-icon>grading</v-icon></v-btn>
        <v-btn class="btn-bar" icon dark title="Packs"><v-icon>shopping_cart</v-icon></v-btn>
        <v-btn class="btn-bar" icon dark title="Editar"><v-icon>edit</v-icon></v-btn>
        <v-btn class="btn-bar" color="red" icon dark title="Eliminar"><v-icon>delete</v-icon></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import UserRepository from "@/repositories/UserRepository";

export default {
  data() {
    return {
      user: {
        name: "",
        surname: "",
        dni: "",
        tlf: "",
        email: "",
        rol: "",
        username: "",
        firebase_uid: ""
      },
    };
  },

  mounted() {
    this.fechData()
  },

  methods: {
    async fechData() {
      try {
        this.user = await UserRepository.findUserById(this.$route.params.user_id);
      }
      catch (e) {
          this.$notify({
              type: "error",
          });
      }
    },

    getUserRol(rol) {
      switch (rol) {
        case "assitant" : return "Asistente"
        case "creator" : return "Creador"
        case "admin" : return "Administrador"
      }
    },

  }
      
};
</script>

<style>

.user-photo {
  width: 150px;
  height: 150px;
  border: 1px solid white;
  border-radius: 50%;
  padding: 5px;
}

.user-card {
  margin-top: 20px;
  padding: 25px;
  padding-left: 50px;
}
</style>
