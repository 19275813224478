import HTTP from "@/common/http";

const resource = "events";

export default {

  async addNewEvent(event) {
    return await HTTP.post(`${resource}/`, event).data;
  },

  async findAllEvents() {
    const response = await HTTP.get(`${resource}`);
    return response.data.body;
  },

  async findEventById(id) {
    const response = await HTTP.get(`${resource}/${id}`);
    return response.data.body;
  },

  async getImageByEventId(id) {
    const response = await HTTP.get(`${resource}/${id}/image`);
    return response.data.body;
  },

  async suscribeToEvent(id, body) {
    const response = await HTTP.post(`${resource}/${id}/subscribe`, body).data;
    return response;
  },

  async updateEvent(body, event_id) {
    const response = await HTTP.put(`${resource}/${event_id}`, body).data;
    return response;
  },

  async deleteEvent(user, event_id) {
    const response = await HTTP.delete(`${resource}/${event_id}`, {data: user});
    return response.data;
  },

};
