
import HTTP from "@/common/http";

export default {

  async createPack(event_id, pack) {
    return await HTTP.post(`events/${event_id}/packs`, pack).data;
  },

  async findAllPacks() {
    let response = await HTTP.get(`packs`);
    return response.data.body;
  },

  async findEventPacks(id) {
    let response = await HTTP.get(`events/${id}/packs`);
    return response.data.body;
  },

  async deletePack(event_id, pack_id) {
    let response = await HTTP.delete(`events/${event_id}/packs/${pack_id}`);
    return response.data.body;
  },

  async getPack(event_id, pack_id) {
    let response = await HTTP.get(`events/${event_id}/packs/${pack_id}`);
    return response.data.body;
  },

  async updatePack(event_id, pack) {
    return await HTTP.put(`events/${event_id}/packs/${pack.id}`, pack).data;
  },

  async addItemToPack(event_id, pack_id, body) {
    return await HTTP.post(`events/${event_id}/packs/${pack_id}/items`, body).data;
  },

  async removeItemPack(event_id, pack_id, item_id) {
    return await HTTP.delete(`events/${event_id}/packs/${pack_id}/items/${item_id}`);
  },

};


