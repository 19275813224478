<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="justify-center">
        <h1 style="color: yellow;">EN PROGRESO...</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
}

</script>

<style scoped>
</style>
