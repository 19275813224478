<template>
  <div class="container">
    <v-data-table dark :headers="headers" :items="notifications" 
          :items-per-page="25"
          :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }">
      <template v-slot:item.sent="{ item }" dark>
        <v-chip :color="getColorStatus(item.sent)">
          {{ getStatus(item.sent) }}
        </v-chip>
      </template>
      <template v-slot:item.event_id="{ item }" dark>
        <v-chip color="purple" @click="goToEventDetail(item.event_id)">
          {{ item.event_id }}
        </v-chip>
      </template>
      <template v-slot:item.creation_date="{ item }">
        <v-chip>
          {{ formatDate(item.creation_date) }}
        </v-chip>
      </template>
      <template v-slot:item.scheduled_date="{ item }">
        <v-chip>
          {{ formatDate(item.scheduled_date) }}
        </v-chip>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon @click="editNotification(item.id)">mdi-pencil</v-icon>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-icon @click="deleteNotif(item.id)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>

</template>

<script>

import moment from "moment"
import NotificationRepository from "../../repositories/NotificationRepository";


export default {
    data () {
      return {
        headers: [
          { text: 'ID', align: 'start', sortable: false, value: 'id' },
          { text: 'Titulo', value: 'title' },
          { text: 'Cuerpo', value: 'description' },
          { text: 'Estado', value: 'sent' },
          { text: 'ID evento', value: 'event_id' },
          { text: 'Creada', value: 'creation_date' },
          { text: 'Programada', value: 'scheduled_date' },
          { text: 'ID usuario', value: 'user_id' },
          { text: 'Editar', value: 'edit' },
          { text: 'Eliminar', value: 'delete' },
        ],
        notifications: [],
      }
    },

  mounted() {
    this.fetchData();
  },

  methods: {

    async fetchData() {
      this.notifications = await NotificationRepository.getNotifications()
      this.notifications.push
    },

    formatDate(value) {
      const date = new Date(value * 1000)
      return (moment(String(date)).format('HH:mm - DD/MM/YYYY'))
    },

    async goToEventDetail(event_id) {
      this.$router.push({name: 'EventDetail', params: { id: event_id }});
    },

    getStatus(status) {
        if (status == 1) return 'Enviada'
        else return 'Pendiente'
    },

    getColorStatus(status) {
        if (status == 1) return 'green'
        else return 'gray'
    },

    async editNotification(notif_id){
      this.$router.push({name: 'EditNotification', params: { id: notif_id }});
    },

    async deleteNotif(id) {
      try {
        await NotificationRepository.deleteNotification(id);
        this.fetchData()
      } catch (err) {
        this.$notify({
          text: err.response.data.message,
          type: "error",
        });
      }
    },

  },
};
</script>

<style scoped>
.select {
  width: 150px;
}
</style>
