
const store = {
  state: {
    user: {
      authority: "ADMIN",
      login: "",
      logged: false,
    },
  },
};

export default store;
