import HTTP from "@/common/http";

const resource = "locations";

export default {

  async getCCAA() {
    let response = await HTTP.get(`${resource}`);
    return response.data.body.ccaas
  },

  async getProvincesByCA(parent_id) {
    let response = await HTTP.get(`${resource}?ccaa=${parent_id}`,);
    return response.data.body.provinces
  },

  async getTownhallsByProvince(parent_id) {
    let response = await HTTP.get(`${resource}/province/${parent_id}`,);
    return response.data.body
  },


};
