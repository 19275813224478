
import HTTP from "@/common/http";

const resource = "items";

export default {

  async addNewItem(item) {
    return await HTTP.post(`${resource}/`, item).data;
  },

  async updateItem(item) {
    return await HTTP.put(`${resource}/${item.id}`, item).data;
  },

  async getImageItem(id) {
    const response = await HTTP.get(`${resource}/${id}/image`);
    return response.data.body;
  },

  async deleteItem(item_id) {
    return await HTTP.delete(`${resource}/${item_id}`);
  },

  async findAllItems() {
    const response = await HTTP.get(`${resource}`);
    return response.data.body;
  },
  
};
